// * * * * * * * * * * * * * *
// * * Import core styles  * *
// * * * * * * * * * * * * * *

// Includes Bootstrap and styling shared across
// all themes using the Start Bootstrap Pro
// design language

@import './core/styles';

// * * * * * * * * * * * * * * * * * *
// * * Import SB Admin Pro styles  * *
// * * * * * * * * * * * * * * * * * *

// Includes styling specific to the SB Admin Pro
// theme - must load after core styles

@import './admin/styles';

// * * * * * * * * * * * * * * * * * * * *
// * * Import Angular specific styles  * *
// * * * * * * * * * * * * * * * * * * * *

// Styling specific to your project
// The file imported below is intentionally blank and should
// be used to create styling specific to your project

@import './sb-admin-pro-angular';

// * * * * * * * * * * * * * * *
// * * Import project styles * *
// * * * * * * * * * * * * * * *

// Styling specific to your project
// The below file is intentionally blank and should
// be used to create styling specific to your project

@import './project';

body{
    background-color: #f6f7fb;
}

.modal-content {
    border-radius: 15px;
}

.modal-content .modal-header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.modal-content .modal-footer {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}


// Esto soluciona los check-Box a la derecha
// .form-check .form-check-input {
//     float: left;
//     margin-left: 0 !important;
//     margin-right: 10px;
// }
