// Global resets

html,
body {
    height: 100%;
}

body {
    overflow-x: hidden;
}

// Bring in custom font for global use
@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-Thin.otf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-ThinItalic.otf');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-ExtraLight.otf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-ExtraLightItalic.otf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-Light.otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-LightItalic.otf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-Regular.otf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-RegularItalic.otf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-Medium.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-MediumItalic.otf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-SemiBold.otf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-SemiBoldItalic.otf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-Bold.otf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-BoldItalic.otf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-ExtraBold.otf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-ExtraBoldItalic.otf');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-Black.otf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-BlackItalic.otf');
    font-weight: 800;
    font-style: italic;
}